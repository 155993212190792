$blue: #2e3191;
$darkBlue: #1f2164;
$black: #000;
$white: #fff;
$red: #c4262e;

$max-width: 1440px;
$headerH1: "35px";
$headerH2: "20px";
$headerH3: "16px";
$headerH4: "14px";

.headingH2 {
  font-size: 35px;
  color: $red;
  font-weight: 700;
  padding-bottom: 50px;
  @media all and (max-width: 992px) { 
    font-size: 25px;
    padding: 0 20px 20px;
  }
}

.headingH4 {
  font-size: 20px;
  color: $blue;
  font-weight: 700;
}

h5 {
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0;
  margin-bottom: 20px;
}

.headingP {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}

.icon {
  height: 61px;
  width: 61px;
  fill: $red;
  vertical-align: middle;
  margin-right: 20px;
  @media all and (max-width: 992px) { 
    height: 40px;
    width: 40px;
  }
}

.container {
  max-width: 1440px;
  margin: 120px 111px 50px;
}

p {
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0;
  margin-bottom: 0;
}

button {
  color: $white;
  background-color: $red;
}
