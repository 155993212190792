.Home {
  position: relative;
  @mixin Flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 100%;
    height: 77vh;
    object-fit: cover;
  }
  .header {
    @include Flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    color: $white;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000354 100%);
    h2 {
      font-weight: 800;
      font-size: 44px;
      line-height: 61px;
      text-align: center;
      letter-spacing: 0.08em;
      @media all and (max-width: 992px) {
        font-size: 20px;
        line-height: 1;
      }
      span{
        padding: 0 .8rem;
        &:first-of-type:before {
          content: "";
        }
        &:before {
          content: "•";
          font-size: 26px;
          text-align: center;
          position: relative;
          bottom: 8px;
          left: -12px;
          @media all and (max-width: 992px) {
            font-size: 10px;
            bottom: 4px;
          }
        }
      }
    }
    h3 {
      font-weight: 700;
      font-size: 35px;
      line-height: 49px;
      text-align: center;
      letter-spacing: 0.08em;
      @media all and (max-width: 992px) {
        font-size: 15px;
      }
    }
  }
}
