.Services {
  margin: 120px 111px 0;
  align-items: center;

  @media all and (max-width: 992px) {
    margin: 60px 0 0;
    display: block;
  }

  @media all and (min-width: 993px) and (max-width: 1300px) {
    margin: 60px 50px 0;
  }

  &__item {
    &--content {
      width: 100%;
      position: relative;
      background: $blue;
      border-radius: 0px 12px 12px 0px;
      margin: 0 130px 0 -112px;
      padding: 61px 50px 67px 111px;
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      text-align: justify;
      color: $white;
      text-indent: 30px;
      box-shadow: $darkBlue -33px 27px 0px 0px;

      @media all and (max-width: 992px) {
        padding: 40px;
        margin: 0;
        box-shadow: none;
        border-radius: 0;
      }

      @media all and (min-width: 993px) and (max-width: 1300px) {
        margin: 0 70px 0 -112px;
      }

      @media all and (min-width: 1920px) {
        padding: 89px 50px 89px 111px;
      }

      @mixin arrowStyle {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        z-index: -1;
        transform: translateY(-50%);
        border-top: 137px solid transparent;
        border-bottom: 137px solid transparent;

        @media all and (max-width: 1300px) {
          display: none;
        }
      }

      &:before {
        @include arrowStyle;
        border-left: 79px solid $darkBlue;
        top: 60%;
        right: -44px;

        @media all and (max-width: 1300px) {
          display: none;
        }
      }

      &:after {
        @include arrowStyle;
        border-left: 60px solid $blue;
        top: 50%;
        right: -59px;
      }
    }

    &:last-of-type {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: start;
      gap: 30px;

      @media all and (max-width: 992px) {
        display: block;
        padding: 40px 20px 0;
      }
    }
  }

  svg {
    font-size: 2rem;
    color: $black;
  }

  @media all and (min-width: 1300px) {
    .Services__item {
      position: relative;
      margin-top: 80px;
      .MuiPaper-root {
        overflow: visible;
      }
    }

    .Card {
      &__content {
        position: absolute;
        top: calc(100% + -10px);
        width: 100%;
        background-color: $white;
        border-top: none;
        z-index: 9999;
        padding: 5px 20px 0;
        border-radius: 0 0 15px 15px;
        border: 1px solid $white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0), 0px 6px rgba(0, 0, 0, 0.23);
      }
    }
  }

  @media all and (max-width: 992px) {
    .MuiPaper-root {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
