.Careers {
  margin-top: 120px;
  @media all and (max-width: 992px) {
    margin: 60px 0;
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 79px;
    position: relative;
    padding: 0 111px;
    align-items: center;
    &::before {
      content: "";
      padding-left: 111px;
      border-bottom: 20px;
      position: absolute;
      z-index: -1;
      transform: translateY(-50%);
      border-top: 415px solid $blue;
      top: 50%;
      right: 0px;
      width: 100%;
      @media all and (max-width: 992px) {
        border-top: 0;
      }
    }
    @media all and (max-width: 992px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
    }
  }
  .Careers__item {
    @media all and (max-width: 992px) {
      background: $blue;
    }
    &--content {
      @media all and (max-width: 992px) {
        padding: 40px 20px;
      }
    }
    img {
      height: 680px;
      width: 100%;
      border-radius: 100%;
      object-fit: cover;
      @media all and (max-width: 992px) {
        display: none;
      }
    }
    a {
      font-family: "Inter", sans-serif;
      background: $red;
      border: 0;
      border-radius: 52px;
      width: 231px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      outline: 0;
      padding: 28px;
      text-align: center;
      color: $white;
      text-transform: capitalize;
      @media all and (max-width: 992px) {
        width: 238px;
        font-size: 16px;
        padding: 14px;
        margin-top: 20px;
      }
      &:hover {
        background: $red;
      }
    }
    h2 {
      position: absolute;
      top: -10px;
    }
    h3 {
      font-size: 65px;
      font-weight: 900;
      color: $white;
      padding-bottom: 20px;
      @media all and (max-width: 992px) {
        font-size: 25px;
      }
    }
    p {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      color: $white;
      padding-bottom: 20px;
      @media all and (max-width: 992px) {
          font-size: 16px;
          padding: 0;
      }
    }
  }
}
