.About {
  margin: 120px 0;
  position: relative;
  @media all and (max-width: 992px) {
    margin-top: 50px;
  }
  @media all and (min-width: 993px) and (max-width: 1300px){
    margin-top: 50px;
  }
  &__container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    @media all and (max-width: 992px) {
      display: flex;
      flex-direction: column-reverse;
    }
    p {
      @media all and (max-width: 992px) {
        background: $blue;
        padding: 40px 20px;
      }
    }
    &:before {
      content: "";
      padding-left: 111px;
      border-bottom: 20px;
      position: absolute;
      z-index: -1;
      transform: translateY(-50%);
      border-top: 277px solid $blue;
      top: 37%;
      right: 0px;
      width: 100%;
      @media all and (max-width: 992px) {
        border-top: 0;
      }
    }
  }
  &__item {
    &--content {
      position: relative;
      border-radius: 0px 12px 12px 0px;
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      text-align: justify;
      color: $white;
      text-indent: 30px;
      padding-right: 111px;
      @media all and (max-width: 992px) {
        padding-right: 0;
      }
      @media all and (min-width: 993px) and (max-width: 1300px){
        padding-right: 50px;
      }
    }
  }
  &__card {
    padding: 50px 111px 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 30px;
    @media all and (min-width: 993px) and (max-width: 1300px){
      padding: 0 50px;
    }
    @media all and (max-width: 992px) {
      display: block;
      padding: 40px 20px 0;
    }
    .MuiCard-root {
      border-radius: 8px;
    }
    .MuiTypography-h5 {
      font-family: "Inter", sans-serif;
      color: $white;
      padding: 14px 38px;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 35px;
      line-height: 59px;
      text-align: center;
      background-color: $red;
    }
    .MuiTypography-body2 {
      font-family: "Inter", sans-serif;
      padding: 14px 44px;
      background: $blue;
      color: $white;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
    }
    .MuiCardContent-root:last-child {
      padding: 0;
    }
    .Card {
      @media all and (max-width: 992px) {
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  video {
    padding: 0 64px 0 111px;
    @media all and (max-width: 992px) {
      padding: 40px 20px 0;
    }
    @media all and (min-width: 993px) and (max-width: 1300px){
      padding: 0 50px;
    }
  }
}
