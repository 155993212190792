.Menu {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.CareersApplyNow {
  overflow-x: hidden;
  &__header {
    padding: 50px 50px 0;
    text-align: center;
    @media all and (max-width: 992px) {
      padding: 40px 40px 0;
    }
    h2 {
      text-align: left;
      padding: 0 0 28px;
    }
    h3 {
      font-weight: 700;
      font-size: 2.8125rem;
      line-height: 1.6;
      text-align: center;
      @media all and (max-width: 992px) {
        font-size: 1.8rem;
      }
    }
    p {
      font-weight: 500;
      font-size: 1.5625rem;
      line-height: 38px;
      padding-top: 14px;
    }
    a {
      text-decoration: none;
      color: $blue;
      font-weight: bold;
    }
  }
  @mixin h4Heading {
    font-size: 2.5rem;
    background: $blue;
    padding: 20px 60px;
    color: $white;
    @media all and (max-width: 992px) {
      font-size: 2rem;
    }
  }

  &__items {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 80px;
    @media all and (max-width: 992px) {
      margin-top: 40px;
    }

    .item {
      flex: 1;
      flex-basis: 0;
      @media all and (max-width: 992px) {
        flex: auto;
        flex-basis: auto;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      &:first-of-type {
        margin-left: -90px;
      }
      h4 {
        @include h4Heading;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }
      h5 {
        font-weight: 700;
        font-size: 1.5625rem;
        line-height: 38px;
        padding-top: 30px;
      }
      li {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 30px;
      }

      img {
        width: 100%;
        height: 755px;
        border-radius: 100%;
        object-fit: cover;
        @media all and (max-width: 992px) {
          display: none;
        }
      }
      .content {
        padding: 0 60px;
        ul {
          margin-left: 20px;
        }
        ul.additionalinfo > li {
          margin-bottom: 20px;
        }
      }
    }
    &:nth-of-type(odd) {
      flex-direction: row-reverse;
      h4 {
        @include h4Heading;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .item {
        &:first-of-type {
          margin-right: -90px;
          margin-left: 0;
        }
      }
    }
  }
}
