.FAQ {
  margin: 120px 111px 0;
  @media all and (max-width: 992px) {
    margin: 50px 0;
  }
  @media all and (min-width: 993px) and (max-width: 1300px){
    margin: 50px;
  }
  &__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    gap: 22px;
    @media all and (max-width: 992px) {
      display: block;
      padding: 20px 20px 0;
    }
  }
  .MuiCollapse-root {
    width: 100%;
    position: absolute;
    max-width: 100%;
    background: #fff;
    border-radius: 0 0 15px 15px;
    transform: translate(0px, 0px);
    z-index: 1;
  }
  .MuiPaper-root {
    margin: 0 0 !important;
  }

  .MuiAccordionSummary-root {
    background: $blue;
    color: $white;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    background: $blue;
    border-radius: 12px;
    @media all and (max-width: 992px) {
      margin-bottom: 20px;
    }

  }
  .MuiTypography-root {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;
    width: 100%;
  }
  .MuiAccordion-region {
    padding: 22px 26px;
  }
  .MuiPaper-root-MuiAccordion-root .Mui-expanded {
    margin: 0;
  }
  .MuiPaper-root.MuiAccordion-root:before {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .MuiPaper-root.MuiAccordion-root {
    box-shadow: unset !important;
  }
}
