.ContactUs {
  margin: 120px 111px 0;
  @media all and (max-width: 992px) {
    margin: 0;
  }
  @media all and (min-width: 993px) and (max-width: 1300px) {
    margin: 50px;
  }
  &__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    @media all and (max-width: 992px) {
      display: block;
    }
    form {
      display: grid;
      gap: 30px;
      position: relative;
      background: $blue;
      padding: 56px 58px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
      @media all and (max-width: 992px) {
        padding: 40px 20px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .MuiInputBase-root {
        border-radius: 12px;
        background: $white;
      }
    }
  }
  button {
    font-family: "Inter", sans-serif;
    background: $red;
    border: 0;
    border-radius: 12px;
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    outline: 0;
    padding: 16px;
    text-align: center;
    color: $white;
    text-transform: capitalize;
    &:hover {
        background: $red;
    }
  }
}
