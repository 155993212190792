.Menu {
  max-width: 100%;
  padding: 0 40px;
  margin: 0 auto;
  @media all and (max-width: 992px) {
    padding: 0 20px;
  }

  img {
    max-width: 229px;
    width: 100%;
    @media all and (max-width: 992px) {
      max-width: 140px;
    }
  
  }
  @mixin Flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  nav {
    @include Flex;
    ul {
      @include Flex;
      @media all and (max-width: 992px) {
        justify-content: normal;
      }
      li {
        padding: 20px 23px 20px 0;
        list-style: none;
        @media all and (min-width: 993px) and (max-width: 1300px){
          padding: 0;
        }
      }
      a {
        @include Flex;
        text-decoration: none;
        color: $black;
        font-family: "Inter";
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
        
      }
      .active {
        padding: 10px;
        background: $red;
        border-radius: 8px;
        color: $white;
        svg {
          fill: $white;
        }
      }
    }
    ol {
      position: absolute;
      background: $white;
      z-index: 1;
      border-radius: 8px;
      min-width: 173px;
      a {
        width: 100%;
      }
    }
  }
  svg {
    width: 32px;
    height: 32px;
    margin-right: 13px;
    fill: $blue;
  }
  &.sticky-nav {
    position: fixed;
    z-index: 3;
    background: $white;
    width: 100%;
    max-width: 100%;
    transition: all 0.2s ease-in-out;
    nav ul li {
      padding: 10px 23px 10px 0;
      @media all and (min-width: 993px) and (max-width: 1300px){
        padding: 10px 5px;
      }
    }
    img {
      max-width: 180px;
      @media all and (min-width: 993px) and (max-width: 1300px){
        max-width: 120px;
      }
    }
  }
  .hamburger {
    display: none;
  }
  @media all and (max-width: 992px) {
    .nav-menu {
      position: fixed;
      left: -100%;
      top: 60px;
      flex-direction: column;
      background-color: rgba(0, 3, 84, 1);
      width: 100%;
      height: 92vh;
      z-index: 999;
      text-align: center;
      transition: 0.3s;
    }
    .nav-menu.active {
      left: 0;
      align-items: flex-start;
      li {
        border-bottom: 1px solid $white;
        width: 100%;
        padding: 20px 20px;
      }
      a {
        color: $white;
        justify-content: left;
      }
      svg {
        fill: $white;
      }
      ol {
        position: unset;
        a {
          color: $black;
        }
      }
    }
    .nav-item {
      margin: 1.5rem 0;
    }
    .hamburger {
      display: block;
    }
  }
}
