.Articles {
  margin-top: 120px;
  @media all and (max-width: 992px) {
    margin-top: 60px;
  }
  .headingH2 {
    padding: 0 111px 50px;
    @media all and (max-width: 992px) {
      padding: 0 20px 20px
    }
    @media all and (min-width: 993px) and (max-width: 1300px) {
      padding: 0 50px 50px;
    }
  }
  &__item {
    gap: 30px;
    padding: 0 111px;
    @media all and (max-width: 992px) {
      padding: 40px 20px;
    }
    @media all and (min-width: 993px) and (max-width: 1300px) {
      padding: 0 50px;
    }

    .Card {
      margin: 0 17px 20px 17px;
      &__content {
        height: 15rem;
        display: flex;
        flex-direction: column;
        @media all and (min-width: 993px) and (max-width: 1300px) {
          height: 19rem;
        }
        @media all and (max-width: 992px) {
          height: auto;
        }
        .headingH4 {
          padding: 10px 0 15px;
        }
        p {
          flex-grow: 1;
        }
      }

      &__actions {
        display: flex;
        justify-content: space-between;
        p {
          color: #737373;
        }
        a {
          font-weight: 600;
          font-size: 16px;
          line-height: 27px;
          text-decoration: none;
          color: $blue;
        }
      }
    }
  }

  .slick-prev {
    left: -5% !important;
    position: absolute;
    z-index: 1;
    @media all and (min-width: 1920px) {
      left: -3% !important;
    }
  }
  .slick-next {
    right: -5% !important;
    position: absolute;
    z-index: 1;
    @media all and (min-width: 1920px) {
      right: -3% !important;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 51px;
    color: gray;
    margin-top: 20px;
  }
}
