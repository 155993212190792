.Footer {
  background: #eeeeee;
  margin-top: 120px;
  @media all and (max-width: 1300px) {
    margin-top: 50px;
  }

  img {
    max-width: 100%;
    @media all and (max-width: 992px) {
      max-width: 50%;
    }
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
    padding: 50px 111px 20px;
    @media all and (max-width: 992px) {
      display: block;
      padding: 40px;
    }
    @media all and (min-width: 993px) and (max-width: 1300px) {
      padding: 50px;
    }
    svg {
      vertical-align: middle;
      margin-right: 10px;
    }
    &__item {
      @media all and (max-width: 992px) {
        display: block;
        padding: 40px;
      }
    }
  }
  &__social img {
    max-width: 40px;
    margin: 0 10px 20px 0;
    @media all and (max-width: 992px) {
      max-width: 30px;
    }
  }
  p {
    padding-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: $black;
  }
  &__copyright {
    background: $blue;
    p {
      padding: 10px 0 10px;
      text-align: center;
      color: $white;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
