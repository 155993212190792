.Blogs {
  padding: 0 111px;
  margin: 60px 0;
  display: grid;
  grid-template-columns: repeat(1, 2fr 1fr);
  gap: 40px;
  @media all and (max-width: 992px) {
    display: block;
    padding: 0 40px 20px;
  }
  h2 {
    margin-bottom: 20px;
  }
  p {
    text-align: justify;
  }
  &__item {
    img {
      max-width: 100%;
      margin-bottom: 20px;
    }
    ul li {
      list-style: none;
      padding-bottom: 5px;
      a {
        color: $black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .Card {
      @media all and (max-width: 992px) {
        margin-top: 40px;
      }
    }
  }
}
